import { default as _91id_93hDiYGcuWhYMeta } from "/tmp/8dcd354ca6dbe00/pages/applicant/[id].vue?macro=true";
import { default as _91id_93XARdgP25seMeta } from "/tmp/8dcd354ca6dbe00/pages/apply/[id].vue?macro=true";
import { default as _91id_93Ob8CZxQXNxMeta } from "/tmp/8dcd354ca6dbe00/pages/apply/perfil/[id].vue?macro=true";
import { default as thanksBCb9s3EQipMeta } from "/tmp/8dcd354ca6dbe00/pages/apply/thanks.vue?macro=true";
import { default as _91_46_46_46slug_93DuRmaxNAZOMeta } from "/tmp/8dcd354ca6dbe00/pages/articles/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_931YXx8FRaR1Meta } from "/tmp/8dcd354ca6dbe00/pages/auth/password/[...slug].vue?macro=true";
import { default as forgotGr1ULtFHtbMeta } from "/tmp/8dcd354ca6dbe00/pages/auth/password/forgot.vue?macro=true";
import { default as _91_46_46_46slug_937eb8r7rM0wMeta } from "/tmp/8dcd354ca6dbe00/pages/auth/signin/[...slug].vue?macro=true";
import { default as indexc5RgMgPPq7Meta } from "/tmp/8dcd354ca6dbe00/pages/auth/signin/index.vue?macro=true";
import { default as _91email_938vtJOjTACrMeta } from "/tmp/8dcd354ca6dbe00/pages/auth/signup/[email].vue?macro=true";
import { default as indexkhfNLgx1YTMeta } from "/tmp/8dcd354ca6dbe00/pages/auth/signup/index.vue?macro=true";
import { default as _91_46_46_46slug_93aQWLCurGJfMeta } from "/tmp/8dcd354ca6dbe00/pages/auth/verifyemail/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93xaSrYY865dMeta } from "/tmp/8dcd354ca6dbe00/pages/biblioteca/[...slug].vue?macro=true";
import { default as indexh77x0DNzgwMeta } from "/tmp/8dcd354ca6dbe00/pages/biblioteca/index.vue?macro=true";
import { default as jobsHITi3rDmwOMeta } from "/tmp/8dcd354ca6dbe00/pages/company/[id]/jobs.vue?macro=true";
import { default as indexeHNBSLkcBdMeta } from "/tmp/8dcd354ca6dbe00/pages/company/index.vue?macro=true";
import { default as onboardingnffTCQ6alKMeta } from "/tmp/8dcd354ca6dbe00/pages/company/onboarding.vue?macro=true";
import { default as gestao_pessoasmeQgHXg6QHMeta } from "/tmp/8dcd354ca6dbe00/pages/gestao_pessoas.vue?macro=true";
import { default as indexXJQ6Ls9v6rMeta } from "/tmp/8dcd354ca6dbe00/pages/index.vue?macro=true";
import { default as _91applicant_937u4Mb8FY0WMeta } from "/tmp/8dcd354ca6dbe00/pages/job/[job]/applicants/[applicant].vue?macro=true";
import { default as indexxWw9BrKRSqMeta } from "/tmp/8dcd354ca6dbe00/pages/job/[job]/applicants/index.vue?macro=true";
import { default as indexrp4F0YjfeXMeta } from "/tmp/8dcd354ca6dbe00/pages/job/[job]/index.vue?macro=true";
import { default as archivedxg8WvJP5sWMeta } from "/tmp/8dcd354ca6dbe00/pages/job/archived.vue?macro=true";
import { default as closedlAGkTl2tyrMeta } from "/tmp/8dcd354ca6dbe00/pages/job/closed.vue?macro=true";
import { default as newIiZLCqV3YEMeta } from "/tmp/8dcd354ca6dbe00/pages/job/new.vue?macro=true";
import { default as openedaEvSHfMy9EMeta } from "/tmp/8dcd354ca6dbe00/pages/job/opened.vue?macro=true";
import { default as paused8OjrkSkDEqMeta } from "/tmp/8dcd354ca6dbe00/pages/job/paused.vue?macro=true";
import { default as onboardingp2p7LZvG2oMeta } from "/tmp/8dcd354ca6dbe00/pages/onboarding.vue?macro=true";
import { default as pesquisasHibfusId7TMeta } from "/tmp/8dcd354ca6dbe00/pages/pesquisas.vue?macro=true";
import { default as indexR46H8P0yGmMeta } from "/tmp/8dcd354ca6dbe00/pages/plans/index.vue?macro=true";
import { default as settingswPFLtVa0fJMeta } from "/tmp/8dcd354ca6dbe00/pages/settings.vue?macro=true";
import { default as index3fM5jYcdeYMeta } from "/tmp/8dcd354ca6dbe00/pages/thanks/index.vue?macro=true";
import { default as indexzxqWpdUWwpMeta } from "/tmp/8dcd354ca6dbe00/pages/user/index.vue?macro=true";
export default [
  {
    name: "applicant-id",
    path: "/applicant/:id()",
    meta: _91id_93hDiYGcuWhYMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/applicant/[id].vue").then(m => m.default || m)
  },
  {
    name: "apply-id",
    path: "/apply/:id()",
    component: () => import("/tmp/8dcd354ca6dbe00/pages/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: "apply-perfil-id",
    path: "/apply/perfil/:id()",
    component: () => import("/tmp/8dcd354ca6dbe00/pages/apply/perfil/[id].vue").then(m => m.default || m)
  },
  {
    name: "apply-thanks",
    path: "/apply/thanks",
    component: () => import("/tmp/8dcd354ca6dbe00/pages/apply/thanks.vue").then(m => m.default || m)
  },
  {
    name: "articles-slug",
    path: "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93DuRmaxNAZOMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-slug",
    path: "/auth/password/:slug(.*)*",
    component: () => import("/tmp/8dcd354ca6dbe00/pages/auth/password/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/tmp/8dcd354ca6dbe00/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin-slug",
    path: "/auth/signin/:slug(.*)*",
    meta: _91_46_46_46slug_937eb8r7rM0wMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/auth/signin/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: indexc5RgMgPPq7Meta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/auth/signin/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup-email",
    path: "/auth/signup/:email()",
    component: () => import("/tmp/8dcd354ca6dbe00/pages/auth/signup/[email].vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: indexkhfNLgx1YTMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/auth/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-verifyemail-slug",
    path: "/auth/verifyemail/:slug(.*)*",
    component: () => import("/tmp/8dcd354ca6dbe00/pages/auth/verifyemail/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "biblioteca-slug",
    path: "/biblioteca/:slug(.*)*",
    component: () => import("/tmp/8dcd354ca6dbe00/pages/biblioteca/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "biblioteca",
    path: "/biblioteca",
    meta: indexh77x0DNzgwMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/biblioteca/index.vue").then(m => m.default || m)
  },
  {
    name: "company-id-jobs",
    path: "/company/:id()/jobs",
    component: () => import("/tmp/8dcd354ca6dbe00/pages/company/[id]/jobs.vue").then(m => m.default || m)
  },
  {
    name: "company",
    path: "/company",
    meta: indexeHNBSLkcBdMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: "company-onboarding",
    path: "/company/onboarding",
    meta: onboardingnffTCQ6alKMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/company/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "gestao_pessoas",
    path: "/gestao_pessoas",
    meta: gestao_pessoasmeQgHXg6QHMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/gestao_pessoas.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexXJQ6Ls9v6rMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "job-job-applicants-applicant",
    path: "/job/:job()/applicants/:applicant()",
    meta: _91applicant_937u4Mb8FY0WMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/job/[job]/applicants/[applicant].vue").then(m => m.default || m)
  },
  {
    name: "job-job-applicants",
    path: "/job/:job()/applicants",
    meta: indexxWw9BrKRSqMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/job/[job]/applicants/index.vue").then(m => m.default || m)
  },
  {
    name: "job-job",
    path: "/job/:job()",
    meta: indexrp4F0YjfeXMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/job/[job]/index.vue").then(m => m.default || m)
  },
  {
    name: "job-archived",
    path: "/job/archived",
    meta: archivedxg8WvJP5sWMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/job/archived.vue").then(m => m.default || m)
  },
  {
    name: "job-closed",
    path: "/job/closed",
    meta: closedlAGkTl2tyrMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/job/closed.vue").then(m => m.default || m)
  },
  {
    name: "job-new",
    path: "/job/new",
    meta: newIiZLCqV3YEMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/job/new.vue").then(m => m.default || m)
  },
  {
    name: "job-opened",
    path: "/job/opened",
    meta: openedaEvSHfMy9EMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/job/opened.vue").then(m => m.default || m)
  },
  {
    name: "job-paused",
    path: "/job/paused",
    meta: paused8OjrkSkDEqMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/job/paused.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingp2p7LZvG2oMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "pesquisas",
    path: "/pesquisas",
    meta: pesquisasHibfusId7TMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/pesquisas.vue").then(m => m.default || m)
  },
  {
    name: "plans",
    path: "/plans",
    meta: indexR46H8P0yGmMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingswPFLtVa0fJMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "thanks",
    path: "/thanks",
    meta: index3fM5jYcdeYMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/thanks/index.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: indexzxqWpdUWwpMeta || {},
    component: () => import("/tmp/8dcd354ca6dbe00/pages/user/index.vue").then(m => m.default || m)
  }
]